.contact {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  text-align: center;
  margin: auto;
  padding: 2rem;
  width: 100%;

  h1 {
    color: rgb(0,254,255);
    margin-bottom: 2rem;
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;

    &-label {
      color: rgb(0,254,255);
    }

    &-icon, &-divider {
      color: gray !important;
    }

    &-icon {
      margin-right: 1rem !important;
    }

    &-divider {
      margin: 0 1rem;
    }

    &-link {
      a {
        color: white;
      }
    }
  }
}

@media (max-width: 800px) {
  .contact-row-label {
    width: 100%;
  }
}
