.navbar {
  display: flex !important;
  justify-content: space-between !important;
  align-items: stretch !important;
  flex-wrap: wrap !important;
  min-height: auto !important;
  height: auto !important;
  z-index: 10 !important;
  margin-bottom: 0 !important;
  min-height: 4rem !important;
  position: fixed;
  left: 0 !important;
  right: 0 !important;

  &:after {
    content: normal !important;
  }

  &-title {
    text-align: left;
    padding: 0.7rem;
    cursor: pointer;

    .text {
      font-weight: bold;
      text-transform: uppercase;
      color: rgb(0,254,255);
      font-size: 1.3rem;
      margin-bottom: 0.1rem;
    }

    .sub-text {
      font-size: 0.9rem;
    }
  }

  &-external-links {
    display: flex;
    align-items: center;

    .external-link-icon {
      color: inherit;

      &:hover {
        transform:scale(1.3);
      }
  
      &:not(:last-of-type) {
        margin-right: 1.5rem !important;
      }
    }
  }

  &.ui.inverted.menu {
    .navbar-routes .active.item {
      color: rgb(0,254,255) !important;
    }
  }
}
