$animation-duration-long: 1.1s;
$animation-duration-short: 0.5s;

.home {
  height: 100%;
  scroll-snap-type: y mandatory;

  &-section {
    height: calc(100%);
    overflow: hidden;
    background-color: rgb(45, 55, 65);
    scroll-snap-align: start;
    position: relative;
  }

  &-shape {
    width: 70%;
    height: 100%;

    &.right {
      float: right;
      shape-outside: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
      clip-path: polygon(30% 0, 100% 0, 100% 100%, 0 100%);
      shape-margin: 10px;
      background-image: url('../../assets/backgrounds/california.jpg');
      background-position: 50% calc(50% + 100px);
      animation: inflate forwards;
      animation-duration: $animation-duration-long;
      animation-delay: ($animation-duration-long * 2);

      &:before {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        background-image: url('../../assets/backgrounds/newyork.jpg');
        background-size: cover;
        background-position: center;
        opacity:0;
    
        animation: opacity 0.5s forwards;
        animation-delay: ($animation-duration-long * 3.5);
      }

      &:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        background-image: url('../../assets/backgrounds/beach.jpg');
        background-size: cover;
        background-position: center;
        opacity:0;
    
        animation: opacity 0.5s forwards;
        animation-delay: ($animation-duration-long * 5.5);
      }
    }

    &.left {
      float: left;
      shape-outside: polygon(0 0, 60% 0, 100% 100%, 0 100%);
      clip-path: polygon(0 0, 60% 0, 100% 100%, 0 100%);
      shape-margin: 10px;
      background-image: url('../../assets/backgrounds/office.jpg');
      background-position: left;
      background-size: cover;
    }
  }

  &-intro {
    width: 80%;
    font-family: 'Indie Flower', cursive;
    color: rgb(0,254,255);
    font-size: 2.5rem;
    line-height: 2.5rem;
    text-align: left;
    margin-bottom: 1.5rem;
    margin-left: 3rem;

    div:nth-of-type(3), div:nth-of-type(4) {
      margin-left: 1rem;
    }

    div:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &-header {
      color: rgb(105,105,105);
    }

    &-row {
      overflow: hidden;
      white-space: nowrap;
      animation: typewriter forwards;
      display: inline-block;
      position: relative;
      animation-timing-function: steps(25, end);
      visibility: hidden;

      &.one {
        animation-duration: $animation-duration-short;
        animation-delay: ($animation-duration-long * 6);
      }
      &.two {
        animation-duration: $animation-duration-short;
        animation-delay: ($animation-duration-long * 6) + $animation-duration-short;
      }
      &.three {
        animation-duration: $animation-duration-short;
        animation-delay: ($animation-duration-long * 6) + ($animation-duration-short * 2);
      }
      &.four {
        animation-duration: $animation-duration-short;
        animation-delay: ($animation-duration-long * 6) + ($animation-duration-short * 3);
      }
      &.five {
        animation-duration: $animation-duration-short;
        animation-delay: ($animation-duration-long * 6) + ($animation-duration-short * 4);
      }
    }
  }

  &-content {
    padding: 1rem;
    height: 100%;
    width: 100%;

    .about {
      &-row {
        font-size: 4rem;
        line-height: 5.3rem;
        overflow: hidden;
        white-space: nowrap;
        animation: typewriter forwards;
        display: inline-block;
        position: relative;
        animation-timing-function: steps(25, end);
        visibility: hidden;
        font-weight: bold;

        &:first-of-type {
          font-weight: normal;
          font-size: 3rem;
          line-height: 3.5rem;
        }

        &.one {
          animation-duration: $animation-duration-long;
          animation-delay: 0.2s;
        }

        &.two {
          animation-duration: $animation-duration-long;
          animation-delay: $animation-duration-long;
        }

        &.three {
          animation-duration: $animation-duration-long;
          animation-delay: $animation-duration-long * 3;
        }

        &.four {
          animation-duration: $animation-duration-long;
          animation-delay: $animation-duration-long * 5;
        }
      }
    }

    .career {

      &-row {
        font-size: 1.3rem;
        margin-bottom: 1rem;
        line-height: 2rem;

        .projects-link {
          color: rgb(0,254,255);
        }
      }
    }

    .skills {
      span {
        padding: 0 1rem 1rem 0.5rem;
        display: inline-block;
        font-weight: bold;
        font-size: 1.3rem;
      }

      &-header {
        margin-top: 1.5rem;
      }
    }

    &-header {
      color: rgb(0,254,255);
      text-align: left;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      font-size: 2rem;
      font-weight: bold;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

.more-link {
  position: absolute;
  right: 0;
  bottom: 10px;
  color: white !important;
  font-size: 2.5rem !important;

  &.animated {
    animation-duration: 1800ms;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bouncer;
    animation-delay: ($animation-duration-long * 6) + ($animation-duration-short * 5);
  }
}

@media screen and (max-width: 1065px) {
  .home-section {
    height: auto;
  }
}

@media screen and (max-width: 630px) {
  .about-row {
    font-size: 2.2rem !important;
    line-height: 2.7rem !important;
  }

  .home-intro {
    font-size: 2rem !important;
    line-height: 2rem !important;
  }

  .home-content-header {
    margin-top: 1rem;
  }

  .more-link {
    display: none;
  }
}

@keyframes typewriter {
  0% {
    visibility: visible;
    max-width: 0;
  }
  100% {
    max-width: 11em;
    visibility: visible;
  }
}

@keyframes bouncer {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.1,.9)   translateY(0); }
  30%  { transform: scale(.9,1.1)   translateY(-15px); }
  50%  { transform: scale(1.05,.95) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

@keyframes inflate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes changeimage {
  0%,100%  {
    background-image: url('../../assets/backgrounds/california.jpg');
  }
}

@keyframes opacity {
  0%  {
    opacity: 0;
  }
  25%  {
    opacity: 0.25;
  }
  50%  {
    opacity: 0.50;
  }
  75%  {
    opacity: 0.75;
  }
  100%  {
    opacity: 1;
  }
}
