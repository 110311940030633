.projects {
  height: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2rem;
  overflow-y: auto;

  &-card {
      margin: 0 2rem 4rem 2rem !important;

    &-flagged-header {
      position: absolute;
      background-color: rgb(218, 0, 0);
      width: 100%;
      z-index: 10;
      text-align: center;
      font-size: 2rem;
      line-height: 3rem;
      font-weight: bold;
    }

    &-buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      .button:first-of-type {
        margin-right: 1rem !important;
      }
    }

    &-source {
      margin-top: 0.5rem;

      span {
        font-style: italic;
      }
    }
  }
  
}